<template>
  <div class="team-member-wrap team-show-member-wrap">
    <!-- 搜索框开始 -->
    <div class="search-wrap align-center">
      <div class="search-box">
        <img src="~assets/images/public/search-icon.png" class="search-icon" />
        <form action="javascript:void 0" style="width: 100%;height: 100%">
          <input type="text" placeholder="搜索队员"  maxlength="20" @keyup.enter="searchConfirm" v-model="search_inp"  class="search-inp" />
        </form>
        <img src="~assets/images/public/search-cancel-icon.png" class="search-cancel-icon" v-if="search_inp.trim().length > 0" @click="cancelSearch" alt="">
      </div>
      <div class="search-btn" @click="searchConfirm">搜索</div>
    </div>
    <!-- 搜索框结束 -->

    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="teamNumber.length > 9 ? '没有更多了': ''"
      :immediate-check="false"
      @load="onLoad"
    >
      <van-checkbox-group v-model="result" checked-color="#FF6B29" @change="checkboxFun" ref="checkboxGroup">
        <van-checkbox :name="item.wechat ? item.wechat.user_id : null"  v-for="item in teamNumber" :key="item.id"  :disabled="item.is_group_team_master == 1">
      <div class="team-member-item vertical-center">
        <img :src="item.wechat && item.wechat.avatar != '' ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" class="user-avatar" alt="">
        <div class="item-r">
          <div class="item-r-top item-show-user vertical-center" style="display: flex;justify-content: unset;">
            <p class="name white-space">{{ item.wechat ? item.wechat.nickname : '酸谈社群' }}</p>
            <div class="serial-number">编号:{{item.number}}</div>
            <div class="user-identity align-center" v-if="item.is_group_team_master == 1">
              <p>小队长</p>
            </div>
            <img src="~assets/images/user/team-edit-icon.png" v-if="item.is_group_team_master != 1" @click.stop="editFun(item, index)" class="edit-icon" alt="">
          </div>
          <div class="item-r-info vertical-center">
            <p class="integral-leave">Lv{{item.rule ? item.rule.detail.name : 0}}</p>
            <div class="experience-num vertical-center">
              <img src="~assets/images/user/team-experience-icon.png" alt="">
              <p>{{item.account ? $handle.tranNumber( item.account.experience, 3) : 0}}</p>
            </div>
          </div>
        </div>
      </div>
        </van-checkbox>
      </van-checkbox-group>
      <no-data v-if="teamNumber.length == 0 && list_status"></no-data>
    </van-list>
    <!--编辑小队成员开始-->
    <van-popup
        v-model:show="edit_show"
        :safe-area-inset-bottom="true"
        round
        position="bottom"
    >
      <div class="edit-team-member-pop">
        <div class="edit-team-btn-item" @click="moveTeam">移出小队</div>
        <div class="edit-team-btn-item" @click="edit_show=false">取消</div>
      </div>
    </van-popup>
    <!--编辑小队成员结束-->

    <!--底部编辑开始-->
    <div class="bottom-btn-height safety-height"></div>
    <div class="edit-bottom-fixed safety-height" v-if="teamNumber.length > 0">
      <div class="edit-bottom-cont space-between">
        <div class="check-all align-center">
          <van-checkbox v-model="checkboxAll" checked-color="#FF6B29" @click="checkAll">全选</van-checkbox>
        </div>
        <div class="edit-btn" :class="{'edit-btn-default': result.length == 0}" @click="switchTeams">转出</div>
      </div>
    </div>
    <!--底部编辑结束-->

    <!-- 加载提示   -->
    <van-overlay :show="login_loading_show" z-index="9999">
      <div class="loading-wrap">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import NoData from "@/components/NoData";
export default {
name: "TeamShow",
  data(){
    return {
      teamNumber: [] ,    //小队成员
      page: 1,            //页码
      loading: false,
      finished: false,
      list_status: false,
      edit_show: false,
      current_data: null,
      login_loading_show: false,
      search_inp: '',
      result: [],   //队员选择
      team_group_type: null,  // 转对操作类型
      change_team: false, // 选择小队弹框
      checkboxAll: false, //  全选状态
    }
  },
  watch: {
    teamNumber(old, new_val) {
      if (new_val.length != this.result.length) {
        this.checkboxAll = false
      }
    }
  },
  created() {
    if(this.userInfo && this.userInfo.is_group_master != 1){
      this.$router.replace('/user')
    }
    this.getTeamDetail()
    this.getTeamNumber()
  },
  methods:{
    // 获取队员数据
    getTeamNumber() {
      let url = this.$api.userNembers + '/-1?page=' + this.page;
      this.list_status= false
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.page++
          this.loading = false;
          if(!res.data.data.data.length){
            this.finished = true;
          }
          this.teamNumber = this.teamNumber.concat(res.data.data.data)
          setTimeout(()=>{
            this.list_status = true
          },300)
        }
      })
    },
    // 获取小队详情
    getTeamDetail() {
      let url = this.$api.TeamGroups + '/0'
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.$store.commit('M_TITLE', res.data.data.name)
        }
      })
    },
    // 全选点击
    checkAll() {
      const {checkboxGroup} = this.$refs
      if (this.checkboxAll) {
        checkboxGroup.toggleAll(true)
        this.result.shift()
      } else {
        checkboxGroup.toggleAll(false)
      }
    },
    // 选择方法
    checkboxFun(value) {
      if ((this.result.length === this.teamNumber.length -1 || this.result.length === this.teamNumber.length) && this.teamNumber.length > 0) {
        this.checkboxAll = true
      } else {
        this.checkboxAll = false
      }
    },
    // 转对设置
    switchTeams() {
      if (this.result.length > 0) {
        this.team_group_type = 'multiple'
        this.change_team = true
        this.edit_show= true
      }
    },
    // 底部加载
    onLoad() {
      if(this.list_status && this.teamNumber.length > 8) {
        this.getTeamNumber()
      }
    },
    // 列表设置默认
    teamDefault(){
      this.finished = false;
      this.loading = false;
      this.page = 1
      this.teamNumber= []
      this.result = []
      this.current_data = null
    },
    // 编辑
    editFun(item, index) {
      this.edit_show= true;
      this.current_data= item
    },
    moveTeam() {
      this.login_loading_show= true;
      let url= this.$api.teamSet
      let data
      if(this.current_data){
        data= {
          type: 4,
          user_id: this.current_data.user_id
        }
      }else if(this.result){
        data= {
          type: 4,
          user_id: this.result.toString()
        }
      }
      this.$http.post(url, data, true).then(res =>{
        if(res.data.success) {
          this.teamDefault()
          this.getTeamNumber()
          this.edit_show = false
          setTimeout(()=>{
            this.login_loading_show= false;
          },1000)
          setTimeout(()=>{
            this.$toast('设置成功')
          },1200)
        }else {
          this.$toast(res.data.message)
        }
      }).catch(error => {
        this.$toast(error.response.data.message)
        this.login_loading_show= false;
      })
    },
    // 搜索事件
    searchConfirm() {
      if(this.search_inp.trim().length == 0){
        return false
      }
      let url= this.$api.userMembers+ '/-1' + '?type=1&page=1&keywords='+ this.search_inp;
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          let _data = res.data.data.data
          if(_data) {
            this.teamNumber = _data
            this.finished = true;
          }else {
            this.teamNumber = []
          }
        }else {
          this.$toast(res.data.message)
        }
      })
    },
    // 取消搜索事件
    cancelSearch() {
      this.search_inp= ''
      this.teamDefault()
      this.getTeamNumber()
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  components: {
    NoData
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team-management/team-member.scss";
.loading-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
